<template>
  <el-dialog
    class="stu-order-detail-dialog"
    :title="`学生信息登记-${title.stuName}${title.stuNo?'学号:'+title.stuNo:'录取编号:'+title.examineNo}`"
    :visible.sync="show"
    width="1300px"
    :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-divider content-position="left">基础信息</el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="个人电话" prop="fixedPhone">
            <el-input v-model.trim="formData.fixedPhone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="家庭住址" prop="addressDetails">
            <el-input v-model.trim="formData.addressDetails" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="毕业学校" prop="hisSchName">
            <el-input v-model.trim="formData.hisSchName" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="入学时间">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="formData.hisEntryDate"
              type="date"
              placeholder="选择日期" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="毕业时间">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="formData.hisGraduateDate"
              type="date"
              placeholder="选择日期" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="证明人" prop="hisCertifier">
            <el-input v-model.trim="formData.hisCertifier" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="联系电话" prop="hisCertifierPhone">
            <el-input v-model.trim="formData.hisCertifierPhone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="父亲姓名" prop="fatherName">
            <el-input v-model.trim="formData.fatherName" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="父亲电话" prop="fatherPhone">
            <el-input v-model.trim="formData.fatherPhone" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="母亲姓名" prop="motherName">
            <el-input v-model.trim="formData.motherName" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="母亲电话" prop="motherPhone">
            <el-input v-model.trim="formData.motherPhone" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">个人信息</el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="身高(cm)" prop="height">
            <el-input v-model.trim="formData.height" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="体重(kg)" prop="weight">
            <el-input v-model.trim="formData.weight" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="鞋码" prop="shoeSize">
            <el-input v-model.trim="formData.shoeSize" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="衣服尺码" prop="coatSize">
            <el-input v-model.trim="formData.coatSize" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="裤码" prop="pantSize">
            <el-input v-model.trim="formData.pantSize" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">自学考试专升本院校、专业选择</el-divider>
      <el-row>
        <el-col :span="10">
          <el-form-item label="自学考试专升本院校及专业选择" label-width="246px">
            <el-select
              filterable
              v-model="schMajorName"
              @change="handleHopeSchMajor"
            >
              <el-option
                v-for="{schName,majorName,id} in schMajorList"
                :label="`${majorName}(${schName})`"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save(`学生${title}的学生信息登记`,saveForStuSignProcess)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveForStuSignProcess, getStudentById } from '@/api/student'
import dialog from '@/vue/mixins/dialog'
import { listSchBachelorInfoApi } from '@/api/count'

export default {
  mixins: [dialog],
  data () {
    return {
      schMajorList: [], // 意向专业 list
      schMajorName: '',
      formData: {
        id: '', // id
        userId: '', // userId
        fixedPhone: '', // 个人电话
        addressDetails: '', //  家庭住址
        hisSchName: '', // 毕业学校
        hisEntryDate: '', // 入学时间
        hisGraduateDate: '', // 毕业时间
        hisCertifier: '', // 证明人
        hisCertifierPhone: '', // 联系电话
        fatherName: '', // 父亲姓名
        fatherPhone: '', // 父亲电话
        motherName: '', // 母亲姓名
        motherPhone: '', // 母亲电话
        height: null, // 身高(cm)
        weight: null, // 体重(kg)
        shoeSize: null, // 鞋码
        coatSize: null, // 衣服尺码
        pantSize: null, // 裤码
        bachelorIntentionSchool: '', // 专升本意向学校
        bachelorIntentionMajor: '' // 专升本意向专业
      },
      rules: {
        addressDetails: {
          max: 200,
          message: '仅限200字内。',
          trigger: 'blur'
        },
        hisCertifier: this.$store.state.commonValidate.limit20WordsObj,
        hisSchName: this.$store.state.commonValidate.limit30WordsObj,
        fatherName: this.$store.state.commonValidate.limit20WordsObj,
        motherName: this.$store.state.commonValidate.limit20WordsObj,
        hisCertifierPhone: this.$store.state.commonValidate.validatorPhoneObj,
        fixedPhone: this.$store.state.commonValidate.validatorPhoneObj,
        fatherPhone: this.$store.state.commonValidate.validatorPhoneObj,
        motherPhone: this.$store.state.commonValidate.validatorPhoneObj,
        shoeSize: [
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
          {
            validator: (rule, value, callback) => {
              if (value && (value < 30 || value > 50)) {
                return callback(new Error('鞋码必须在30码至50码之间!'))
              } else {
                return callback()
              }
            },
            trigger: 'blur'
          }],
        pantSize: this.$store.state.commonValidate.limit20WordsObj,
        weight: this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
        height: this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
        coatSize: this.$store.state.commonValidate.limit20WordsObj
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getStudentById)
      }
      // stuGrade:学生的grade
      if (this.title.stuGrade) {
        this.getSchMajorList(this.title.stuGrade)
      }
    })
  },
  methods: {
    saveForStuSignProcess, // 保存api
    // 根据grade意愿学校和专业 list
    async getSchMajorList (grade) {
      try {
        const res = await listSchBachelorInfoApi({ grade })
        // 只显示已启用的数据 startUpFlag=1
        this.schMajorList = res.data.filter(({ startUpFlag }) => startUpFlag === 1)
      } catch (e) {
      }
    },
    // 获取意愿学校和专业
    handleHopeSchMajor (val) {
      this.formData.bachelorIntentionSchool = ''
      this.formData.bachelorIntentionMajor = ''
      if (val) {
        const _result = this.schMajorList.filter(({ id }) => id === val)[0]
        this.formData.bachelorIntentionSchool = _result.schName
        this.formData.bachelorIntentionMajor = _result.majorName
      }
    }
  }
}
</script>
