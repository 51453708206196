<template>
  <el-dialog class="add-student-order" title="学费订单信息" :visible.sync="show"
             width="1100px"
             :before-close="cancel">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-divider content-position="left">基础信息</el-divider>
      <el-descriptions :column="4" border>
        <el-descriptions-item label-class-name="descriptions-item__label" label="学年">
          <el-select v-model="formData.year" size="small" @change="changeYear">
            <el-option v-for="{ name, type } in $store.state.systemParam.yearItem" :label="name" :value="Number(type)"
                       :key="type" />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="应缴金额">
          <b style="font-size: 16px; color:var(--color-primary);">{{ userInfo.shouldPayAmount }}</b>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="已缴金额">
          <b style="font-size: 16px; color:var(--color-danger);">{{ formData.paidAmount }}</b>
        </el-descriptions-item>
        <el-descriptions-item v-if="userInfo.oweAmount>=0" label-class-name="descriptions-item__label" label="欠费金额">
          <b style="font-size: 16px; color:var(--color-danger);">{{ userInfo.oweAmount }}</b>
        </el-descriptions-item>
        <el-descriptions-item v-if="userInfo.oweAmount<0" label-class-name="descriptions-item__label" label="待退还">
          <b style="font-size: 16px; color:var(--color-warning);">{{ Math.abs(userInfo.oweAmount) }}</b>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="录取编号">
          {{ userInfo.examineNo }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="姓名">
          {{ userInfo.stuName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="性别">
          {{ Number(userInfo.sexType) === 1 ? '男' : (Number(userInfo.sexType) === 2 ? '女' : '') }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="缴费次数">
          {{ formData.times }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="专业">
          {{ userInfo.majorName }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="班级">
          {{ userInfo.className || '未绑定' }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptions-item__label" label="宿舍">
          <span v-if="userInfo.dormitoryName && userInfo.floorNum">{{ userInfo.dormitoryName }}{{ userInfo.roomName
            }}</span>
          <span class="font-grey" v-else>未分配</span>
        </el-descriptions-item>
        <el-descriptions-item v-if="showRoomPrices" label-class-name="descriptions-item__label" label="宿舍费">
          {{ Number(roomPrices[this.formData.year - 1].roomPrice) === 0 ? '0' : roomPrices[this.formData.year -
        1].roomPrice }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 添加订单 -->
      <el-divider content-position="left">缴费登记</el-divider>
      <el-row>
        <el-col :span="8">
          <el-form-item label="缴费单号" prop="payOrderNo">
            <el-input disabled v-model="formData.payOrderNo" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="缴费方式" prop="payType">
            <el-select v-model="formData.payType" @change="handlePayType">
              <el-option v-for="({ name }, index) in $store.state.systemParam.payType" :label="name" :value="name"
                         :key="index" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="缴费日期">
            <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="formData.payDate"
                            style="width: 100%" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!--        缴费金额{{ formData.payAmount }} refund{{ formData.refund }}-->
        <el-col :span="8">
          <el-form-item label="收费人" prop="cashierUserRealName">
            <el-input v-model.trim="formData.cashierUserRealName" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="应缴金额" prop="shouldPayAmount">
            <el-input v-model.trim="formData.shouldPayAmount" disabled />
          </el-form-item>
        </el-col>
        <!--  标题名 退还 -->
        <el-col v-if="isTypeRefund" :span="8">
          <el-form-item label="退还金额" prop="refund">
            <tool-tips-group is-flex tips-content="默认获取自【待退还】的数值。<br/>请注意：通过模板导入时，退还金额必须填写负数。">
              <el-input :disabled="!userInfo.oweAmount"
                        v-model.trim="formData.refund"
                        @input="formData.payAmount=0-Math.abs($event)">
                <i slot="prefix" class="el-input__icon el-icon-minus"></i>
              </el-input>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <!--  标题名 减免 -->
        <el-col v-else-if="isTypeDeduct" :span="8">
          <el-form-item label="减免金额" prop="refund">
            <tool-tips-group is-flex tips-content="默认获取自【欠费金额】的数值。">
              <el-input
                v-model.trim="formData.refund"
                @input="formData.payAmount=Math.abs($event)"/>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <!--  标题名 默认 -->
        <el-col v-else :span="8">
          <el-form-item label="缴费金额" prop="payAmount">
            <tool-tips-group is-flex tips-content="默认获取自【欠费金额】的数值。">
              <el-input ref="oweAmountRef" :disabled="!userInfo.oweAmount"
                        v-model.trim="formData.payAmount"
                        @input="$refs.form.validateField('deductAmount')" />
            </tool-tips-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8" v-if="!(isTypeRefund||isTypeDeduct)">
          <el-form-item label="减免金额" prop="deductAmount">
            <el-input v-model.trim="formData.deductAmount" :disabled="!userInfo.oweAmount" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!--          v-if="oweAmount<0"-->
          <el-form-item label="欠费金额" prop="oweAmount">
            <div class="read-only-area">{{ oweAmount }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注" prop="remarks">
            <el-input v-model="formData.remarks"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" :disabled="!userInfo.oweAmount" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getStuRoomPrices, addStudentOrderApi } from '@/api/finance-manage/studentOrder'
import dialog from '@/vue/mixins/dialog'
import { throttle } from 'lodash'
import { Decimal } from 'decimal.js'
import { validateTwoDecimalPlacesRequired } from '@/utils/form-validate'

export default {
  mixins: [dialog],
  data() {
    return {
      formData: {
        userId: '',
        year: 1, // 学年
        shouldPayAmount: 0, // 应缴金额
        payOrderNo:
          this.$moment().format('YYYYMMDD') +
          Math.floor(Math.random() * 999999), // 缴费单号
        payType: '', // 缴费方式
        payDate: this.$moment().format('YYYY-MM-DD'), // 缴费日期
        times: 0, // 缴费次数
        payAmount: 0, // 缴费金额
        paidAmount: 0, // 已缴金额
        deductAmount: 0, // 减免金额
        cashierUserRealName: JSON.parse(
          window.sessionStorage.getItem('userInfo')
        ).realName, // 收费人
        oweAmount: 0, // 欠费金额
        refund: 0, // 退还金额
        remarks: ''
      },
      userInfo: {
        examineNo: '',
        stuName: '',
        sexType: '',
        majorName: '',
        className: '',
        floorNum: '',
        dormitoryName: '',
        roomName: '',
        roomTypeId: '',
        userId: '',
        shouldPayAmount: 0,
        oweAmount: 0
      },
      showRoomPrices: false,
      roomTypeInfo: {}, // 宿舍类型
      yearItem: [],
      payType: [],
      rules: {
        cashierUserRealName: [
          {
            required: true,
            message: '请填写收费人',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        payType: {
          required: true,
          message: '请选择缴费方式',
          trigger: 'blur'
        },
        payAmount: [
          {
            required: true,
            message: '请输入缴费金额',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validateNegativeNumberWithTwoDecimalPlaces
        ],
        refund: [
          {
            required: true,
            validator: validateTwoDecimalPlacesRequired,
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (+value === 0) {
                return callback(new Error('该项为必填！'))
              } else if (+value > (Math.abs(this.formData.shouldPayAmount))) {
                const msg = this.isTypeRefund ? '退还金额不能超过待退还!' : '减免金额不能超过应缴金额!'
                return callback(new Error(msg))
              } else {
                return callback()
              }
            },
            trigger: 'blur'
          }],
        oweAmount: {
          validator: (rule, value, callback) => {
            if (value && (value < -99999 || value > 99999)) {
              return callback(new Error('欠费金额不能超过99999'))
            } else {
              return callback()
            }
          },
          trigger: 'blur'
        },
        deductAmount: this.$store.state.commonValidate.validatorTwoDecimalPlacesObj
      }
    }
  },
  computed: {
    isTypeRefund() {
      return this.formData.payType === '退还'
    },
    isTypeDeduct() {
      return this.formData.payType === '减免'
    },
    oweAmount() {
      const _shouldPayAmount = Number(this.formData?.shouldPayAmount)
      const _payAmount = (this.isTypeDeduct || this.isTypeRefund) ? Number(this.formData?.refund) : Number(this.formData?.payAmount)
      const _deductAmount = Number(this.formData?.deductAmount)
      let _result = 0
      // 减免
      if (this.isTypeRefund) {
        _result = new Decimal(_shouldPayAmount).plus(new Decimal(_payAmount)).toNumber()
      } else {
        _result = new Decimal(Number(_shouldPayAmount)).sub(new Decimal(_payAmount)).sub(new Decimal(_deductAmount)).toNumber()
      }
      return _result
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getStudentOrderList()
      if (this.userInfo.roomTypeId) {
        await this.getStuRoomPrices(this.userInfo.userId)
      }
    })
  },
  methods: {
    // 改变学年
    async changeYear(year) {
      this.formData.year = year
      await this.getStudentOrderList()
    },
    // 根据年份查询学生缴费登记
    async getStudentOrderList() {
      try {
        const res = await this.$http
          .getStudentOrderListQueryApi({
            userId: this.userInfo.userId,
            year: this.formData.year
          })
        // oweAmount 欠费
        this.userInfo.oweAmount = res.data.oweAmount
        // refund 退还金额，只能是正数，保存时把正数改为负数
        if (this.isTypeDeduct || this.isTypeRefund) {
          if (this.userInfo.oweAmount < 0) {
            this.formData.payType = '退还'
          }
          this.formData.refund = Math.abs(res.data.oweAmount)
        }
        // paidAmount 已缴
        this.formData.paidAmount = res.data.paidAmount
        this.formData.times = res.data.times
        // 应缴
        this.userInfo.shouldPayAmount = res.data.shouldPayAmount
        this.setInitData()
        // 如果欠费金额不为零，才赋值给缴费金额，用于减少关闭弹窗的校验步骤。
        this.formData.shouldPayAmount = res.data.oweAmount
        this.formData.payAmount = res.data.oweAmount
      } catch (e) {
      }
    },
    // 根据id获取宿舍费用
    getStuRoomPrices(userId) {
      this.showRoomPrices = false
      getStuRoomPrices(userId).then((res) => {
        this.roomPrices = res.data
        this.showRoomPrices = true
      })
    },
    // 缴费类型
    handlePayType(val) {
      this.$refs.form.validateField('payAmount')
      switch (val) {
        case '减免':
          this.formData.deductAmount = 0
          this.formData.payAmount = Math.abs(this.formData.payAmount)
          this.formData.refund = this.formData.payAmount
          break
        case '退还':
          this.formData.deductAmount = 0
          this.formData.refund = Math.abs(this.formData.payAmount)
          this.formData.payAmount = new Decimal(0).sub(Math.abs(this.formData.payAmount || 0))
          break
      }
    },
    // 添加缴费
    handleSave: throttle(async function() {
      if (!this.userInfo.oweAmount) {
        this.$message.error('该生学费已缴清！')
        return
      }
      // if (this.isTypeRefund) {
      //   this.formData.payAmount = new Decimal(0).sub(Number(this.formData.refund))
      // }
      // 获取欠费金额
      this.formData.oweAmount = 0
      this.formData.oweAmount = this.oweAmount
      // 次数加1
      this.formData.times = this.formData.times + 1
      await this.save(`${this.userInfo.stuName}的学费订单信息`, addStudentOrderApi, '已添加订单')
    }, 1500, {})
  }
}
</script>
<style lang="scss">
.add-student-order {
  .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }

  .el-descriptions {
    .descriptions-item__label {
      width: 100px;
    }
  }
}
</style>
